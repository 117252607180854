/*
================================================================================
|                                  CARD PAGE                                  |
================================================================================
*/
.block-hint {
	position: relative;
	display: inline-block;
	&:hover {
		.attribute-hint {
			visibility: visible;
			opacity: 1;
			padding: 10px;
			margin: 10px;
		}
	}
	i.fa {
		border: 1px solid #C4C4C4;
		padding: 1px 4px;
		border-radius: 50%;
		text-align: center;
		margin: 0 5px;
		color: #C4C4C4;
		cursor: pointer;
	}
	.attribute-hint {
		position: absolute;
		top: -15px;
		z-index: 5;
		visibility: hidden;
		opacity: 0;
		width: 300px;
		background: #fff;
		-webkit-transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
		-moz-transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
		-o-transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
		transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
		.title {
			padding: 0;
			font-weight: 600;
		}
	}
}
.box-card {
	&__wrap {
		position: relative;
		display: flex;
		padding: 46px 12px 35px;
		background-color: #fff;
	}
}

.markdown-viewed {
	.card-price {
		width: 100%;
		max-width: 280px;

		&__value {
			justify-content: flex-start;
			align-items: flex-end;
			padding: 0;
			width: max-content;
			line-height: 26px;
		}
		&__old {
			font-size: 18px;
			line-height: 1;

			.icon-rouble {
				font-size: 14px;
			}
		}
		&__credit {
			padding: 0;
		}
	}
	.cheaper-products {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&__title {
			font-size: 16px;
			font-weight: 500;
		}
		&.general {
			margin-bottom: 20px;

			.cheaper-items {
				border: 1px solid #19caaf;
			}
		}
		.cheaper-items {
			padding: 16px;
			border: 1px solid #DAE4EA;

			.cheaper-item {
				display: flex;
				flex-direction: column;
				gap: 16px;

				&__price {
					display: flex;
					justify-content: flex-start;
					align-items: flex-end;
					gap: 16px;
				}
				&__title {
					color: #aeafaf;
				}
				&__status {
					font-weight: 500;
					color: #858585;
				}
			}
		}
		.cheaper-item-info {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
	}
}

.card-slider-box {
	display: flex;
	align-items: flex-start;
	width: 485px;
}

.card-info {
	width: calc(100% - 735px);
}

.card-price {
	width: 250px;
}

.card-slider {
	&__item {
		width: 400px;
		height: 400px;
		border: 1px solid #DAE4EA;
	}

	&__img {
		display: block;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100%;
	}

}

.card-slider-nav {
	width: 70px;
	padding: 35px 0;
	margin: 5px 0;

	&__item {
		position: relative;
		width: 70px;
		height: 70px;
		border: 1px solid #DAE4EA;
		margin: 5px 0;

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 3px;
			background-color: $color-2;
			opacity: 0;
			transition: $transition;
		}

		&.slick-current::before {
			opacity: 1;
		}

	}

	&__img {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: auto 95%;
	}

	.slick-arrow {
		font-size: 24px;
	}

	.slick-arrow.slick-prev {
		top: 0;
		left: 50%;
		transform: translateX(-50%) rotate(90deg);
	}

	.slick-arrow.slick-next {
		top: auto;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%) rotate(90deg);
	}
}

.card-slider-wrap {
	padding-left: 15px;
	width: calc(100% - 70px);
}

.card-info-top {
	position: absolute;
	top: 45px;
	left: 537px;

	h1 {
		margin-bottom: 7px;
		padding-top: 0;
		font-size: 24px;
		line-height: 30px;
	}

	&__availability {
		display: inline-block;
		padding: 3px 16px;
		border-radius: 40px;
		color: #fff;
		background: #019835;
		font-size: 12px;
		line-height: 14px;
	}

	.product-list__rating {
		margin: 0 23px 0 21px;
	}

	&__bottom {
		display: flex;
		align-items: center;
	}

	&__feedback {
		position: relative;
		display: inline-block;
		color: #000;
		font-size: 13px;
		line-height: normal;
		white-space: nowrap;

		&-link {
			border-bottom: 1px solid;
			line-height: 12px;
		}

		span {
			margin: 0 4px 0 8px;
		}
	}

	&__write {
		display: inline-block;
		border-bottom: 1px solid;
		color: #000;
		font-size: 13px;
		line-height: 12px;
	}

	&__compare {
		display: flex;
		align-items: center;
		margin-left: 23px;
		color: #000;
		font-size: 13px;
		line-height: normal;

		&:hover {
			i {
				color: $color-2;
			}
		}

		i {
			margin-right: 12px;
			color: $color-3;
			font-size: 19px;
			transition: $transition;
		}
	}
}

.card-info {
	padding: 111px 30px 0 40px;

	&__row {
		padding: 8px 0;
		border-top: 1px solid rgba(156, 185, 200, .2);
	}

	&__time {
		padding: 10px 0;
		font-size: 12px;
		line-height: 18px;

		span {
			color: $color-3;
			font-weight: bold;
		}
	}
}

.card-info-list-top {
	&__item {
		font-size: 13px;
		line-height: 31px;

		span {
			margin-left: 5px;
			color: rgba(0, 0, 0, 0.7);
			font-weight: 500;
		}
	}

	&__link {
		border-bottom: 1px solid;
		margin-left: 5px;
		color: rgba(0, 0, 0, 0.7);
		font-weight: 500;
		line-height: 12px;
	}
}

.card-info-list-bottom {
	&__item {
		position: relative;
		padding-left: 20px;
		color: rgba(0, 0, 0, 0.7);
		font-weight: 500;
		font-size: 13px;
		line-height: 26px;

		&::before {
			content: "";
			position: absolute;
			top: 9px;
			left: 0;
			display: block;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: $color-4;
		}

	}
}

.card-info-position {
	display: flex;
	align-items: center;

	&__icon {
		color: #648798;
		font-size: 20px;
	}

	&__name {
		margin: 0 3px 0 13px;
		font-weight: 500;
		font-size: 14px;
		line-height: normal;
		.storage_address {
			line-height: 1.4;
		}
		i {
			position: relative;
			top: -10px;
			font-size: 11px;
			cursor: pointer;
		}
	}

	.btn {
		padding: 0 30px;
		border: 2px solid #FF4D00;

		&:hover {
			background-color: #FF4D00;
		}
	}
}

.card-price {
	padding-top: 114px;

	&__top {
		padding: 10px 8px 10px 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		border: 1px solid #DAE4EA;
		border-bottom: none;
		border-radius: 4px 4px 0px 0px;
		background: #FFFFFF;
	}

	&__discount {
		display: flex;
		align-items: center;
		padding: 4px 10px;
		border: 1px solid #DAE4EA;
		border-radius: 4px;
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;

		span {
			padding: 0 2px 0 8px;
			color: $color-3;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
		}

		i {
			color: #000;
			font-size: 10px;
			margin-left: 2px;
		}
	}

	&__old {
		color: rgba(51, 51, 51, .5);
		font-weight: 500;
		font-size: 13px;
		line-height: 15px;
		text-decoration-line: line-through;

		i {
			font-size: 10px;
		}
	}

	&__value {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 12px 0 3px;
		font-weight: 500;
		font-size: 32px;
		line-height: 37px;
		text-align: center;

		i {
			margin-left: 5px;
			font-size: 16px;
		}
	}

	&__basket {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 13px 0;
		border-radius: 0px 0px 4px 4px;
		border: 2px solid $color-2;
		background-color: $color-2;
		color: #fff;
		font-size: 14px;
		line-height: normal;
		text-align: center;

		i {
			font-size: 18px;
			margin-right: 12px;
		}

		&:hover {
			background-color: #fff;
			color: $color-1;
		}
	}

	&__credit {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 17px 0 24px;
		border-bottom: 1px solid rgba(156, 185, 200, .2);

		&-value {
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;

			i {
				padding: 0 2px 0 4px;
				font-size: 10px;
			}
		}

		&-link {
			display: flex;
			align-items: center;
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			color: $color-4;

			&:hover {
				span {
					border-color: $color-2;
				}
			}

			span {
				border-bottom: 1px dashed rgba(100, 135, 152, .4);
			}

			i {
				font-size: 19px;
				margin-right: 6px;
			}
		}
	}

	&__cheap {
		padding: 12px 0 15px;
		text-align: center;
	}

	&__cheap-link {
		display: inline;
		font-size: 13px;
		line-height: 15px;
		border-bottom: 1px solid;
	}

	&__summ {
		width: 100%;
		padding: 11px 15px;
		border: 2px solid #648798;
		border-radius: 4px;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		text-align: center;

		&:hover {
			border-color: $color-2;
			background-color: $color-2;
			color: #fff;
		}
	}
}

/*----------------------------------*/

.box-card-tabs {
	&__wrap {
		background-color: #fff;
	}

	.nav-tab-list {
		display: flex;
		margin: 0 -5px;

		&__item {
			position: relative;
			width: 20%;
			padding: 0 .5px;
			text-align: center;
			transition: $transition;

			&::after {
				content: "";
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				width: 100%;
				height: 4px;
				display: block;
				background: $color-2;
				opacity: 0;
				transition: $transition;
			}

			&:hover {
				.nav-tab-list__link {
					background: rgba(100, 135, 152, .1);
				}

				&::after {
					opacity: .5;
				}
			}

			&.active {
				.nav-tab-list__link {
					background-color: #fff;
				}

				&::after {
					opacity: 1;
				}
			}
		}

		&__link {
			width: 100%;
			padding: 18px 15px;
			background: rgba(100, 135, 152, .2);
			font-weight: 500;
			font-size: 18px;
			line-height: 23px;

			&:hover {
				color: $color-1;
			}
		}
	}

	.tab-cont {
		padding: 29px 31px 40px;
		transition: 1s;
	}

	#tab-gifts {
		.product-gifts {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;

			.item {
				display: inherit;
				flex-wrap: inherit;
				align-items: inherit;

				.image {
					width: 200px;
				}

				.title {
					font-size: 18px;
					line-height: 30px;
					max-width: 200px;
				}
			}
		}
	}

}

//Tab 1
.card-tabs-desc {
	max-width: 845px;
	font-size: 13px;
	line-height: 22px;
	color: rgba(51, 51, 51, .8);
	h3 {
		padding-top: 0;
		font-size: 24px;
		line-height: 30px;
		font-weight: normal;
	}

	p {
		color: rgba(51, 51, 51, .8);
	}
	.vtable-th {
		font-weight: 600;
		line-height: 24px;
	}
	table {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 24px;
		tr {
			padding-bottom: 5px;

			td {
				padding: 5px;
				line-height: 22px;
			}
		}
	}
	ul {
		margin-top: 10px;

		li {
			line-height: 24px;
		}
	}
}

//Tab 2
.characteristics-table {
	max-width: 1074px;

	&__item {
		display: flex;
		border-bottom: 1px solid #E0E7EA;
		font-size: 13px;
		line-height: 22px;

		&_title {
			padding: 7px 16px;
			border-top: 1px solid #E0E7EA;
			background-color: #E0E7EA;
			font-weight: bold;
			font-size: 13px;
			line-height: 22px;
		}
	}

	.col {
		display: flex;
		align-items: center;
		width: 50%;
		padding: 6px 16px;
		border-left: 1px solid #E0E7EA;
	}

	.col_2 {
		border-right: 1px solid #E0E7EA;
	}

	.col_3 {
		width: 100%;
		border-right: 1px solid #E0E7EA;
	}
}

//Tab 3
.instruction-list {
	max-width: 1074px;

	&__item {
		display: flex;
		border: 1px solid #E0E7EA;
		border-top: none;
		padding: 10px 16px;
		font-size: 13px;
		line-height: 16px;

		&_title {
			border-top: 1px solid #E0E7EA;
			background-color: #E0E7EA;
			font-weight: bold;
			font-size: 13px;
			line-height: 18px;
		}
	}

	&__download {
		display: flex;
		align-items: center;
		width: 100%;

		&:hover {

			span,
			i {
				color: $color-2;
			}
		}

		span {
			width: calc(100% - 20px);
			padding-left: 15px;
			color: rgba(51, 51, 51, .8);
			transition: $transition;
		}

		i {
			width: 20px;
			color: #9CB1BC;
			font-size: 20px;
			transition: $transition;
		}
	}
}

//Tab 4
.video-list {
	width: 1040px !important;
	margin: 0 auto;

	&-wrap {
		position: relative;
		overflow: hidden;

		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			display: block;
			width: 190px;
			height: 100%;
			background: linear-gradient(270deg, #FFFFFF 11.32%, rgba(255, 255, 255, 0) 100%);
			transition: $transition;
		}
	}

	.slick-list {
		margin: -7px;
		overflow: visible;
	}

	$this: &;

	&__item {
		padding: 7px;
	}

	&__wrap {
		display: block;
		width: 100%;

		&:hover {
			#{$this}__img {
				transform: scale(1.07);
			}

			#{$this}__icon {
				border-color: $color-2;
				color: $color-2;
			}
		}
	}

	&__img-wrap {
		position: relative;
		overflow: hidden;
	}

	&__img {
		position: relative;
		width: 100%;
		height: 166px;
		background-repeat: no-repeat;
		background-size: cover;
		transition: all 2s;

		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			background: rgba(58, 100, 121, 0.5);
			transition: $transition;
		}
	}

	&__icon {
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 38px;
		height: 38px;
		border: 3px solid #fff;
		border-radius: 50%;
		padding-left: 2px;
		font-size: 14px;
		color: #fff;
		transform: translate(-50%, -50%);
		transition: $transition;
	}

	&__info {
		border: 1px solid #DAE4EA;
		border-top: none;
		padding: 12px;
	}

	h2 {
		padding: 0;
		font-size: 16px;
		line-height: 22px;
	}

	p {
		padding: 5px 0 4px;
		color: rgba(51, 51, 51, .5);
		line-height: 20px;
	}
}

.video-list-wrap {
	.slick-arrow {
		top: 83px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 34px;
		height: 34px;
		border-radius: 50%;
		background-color: $color-3;
		color: #fff;
		font-size: 10px;

		&:hover {
			background-color: $color-2;
		}
	}

	.slick-disabled {
		opacity: 0;
	}

	.slick-arrow.slick-prev {
		left: 48px;
	}

	.slick-arrow.slick-next {
		right: 48px;
	}
}

.video-list-btn {
	margin-top: 27px;
	text-align: center;

	.btn {
		height: 50px;
		padding: 0px 32px;
	}
}

.video-list_single {
	.video-list__item {
		width: 100%;
		max-width: 514px;
	}
}

//Tab 5
.card-feedback {
	h2 {
		padding-top: 0;
		margin-bottom: 29px;
		font-size: 24px;
		line-height: 30px;
	}

	&__btn .btn {
		height: 50px;
		padding: 0 34px;
	}
}

.feedback-form {
	padding: 50px 53px;
	background-color: #ECF1F3;

	&-wrap {
		display: none;
		margin-top: 32px;
	}

	&__row {
		max-width: 625px;
		margin: 30px 0;

		&:first-child {
			margin-top: 0;
		}
	}

	&__label {
		display: block;
		margin-bottom: 20px;
		font-weight: 500;
		font-size: 18px;
		line-height: 10px;
	}

	&__input {
		height: 30px;
		border: 1px solid #DAE4EA;
		border-radius: 4px;
		background: #FFFFFF;
	}

	&__bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 625px;
		margin-top: -7px;
	}

	&__cancel {
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
	}

	&__captcha {
		cursor: pointer;
	}

	.btn {
		height: 50px;
		padding: 0 53px;
	}

	.jq-star {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 30px !important;
		height: 30px !important;
		border: 1px solid #DAE4EA;
		border-radius: 4px;
		margin-right: 12px;
		background: #FFFFFF;
	}

	.jq-star-svg {
		width: 13px;
	}
}

.feedback-box {
	&__item {
		margin: 20px 0;
		padding-bottom: 26px;
		background-color: #ECF1F3;
	}

	&__top {
		display: flex;
		align-items: center;
		padding: 20px 28px;
		margin-bottom: 12px;
		border-bottom: 1px solid #DCE6EA
	}

	&__name {
		min-width: 110px;
		font-weight: 500;
		font-size: 18px;
		line-height: 10px;
	}

	&__date {
		display: flex;
		align-items: center;
		margin-left: auto;
		font-size: 16px;
		line-height: 10px;

		i {
			padding-right: 13px;
			color: $color-3;
			font-size: 20px;
		}
	}

	&__row {
		display: flex;
		padding: 10px 28px;

		.col_1 {
			width: 110px;
			font-weight: 500;
			font-size: 15px;
			line-height: 21px;
		}

		.col_2 {
			width: calc(100% - 215px);
			font-size: 14px;
			line-height: 20px;
			color: rgba(51, 51, 51, .8);
		}
	}
}

.feedback-box-btn {
	margin-top: 35px;

	.btn {
		height: 50px;
		padding: 0 34px;
	}
}

/*-------------------------------------------------------------------------------*/

@media (min-width: 1280px) {
	.long_name {
		.card-info {
			padding: 145px 30px 0 40px;
		}
		.card-price {
			padding-top: 145px;
		}
	}

}

@media screen and (max-width: 1279px) {
	.box-card__wrap {
		flex-wrap: wrap;
		padding: 30px 36px;
	}

	.card-info-top {
		position: relative;
		top: 0;
		left: 0;
		max-width: 100%;
		padding-bottom: 18px;
		margin-bottom: 40px;
		border-bottom: 1px solid rgba(156, 185, 200, .2);
		width: 100%;
	}

	.card-info {
		order: 1;
		width: 100%;
		padding: 0;
		margin-top: 24px;
	}

	.card-price {
		width: 275px;
		padding-top: 0px;
		padding-left: 25px;
	}

	.card-info__time {
		max-width: 530px;
	}

	.card-conditions-wrap {
		display: none;
	}

	.card-info__row:first-child {
		border-top: none;
	}
	.markdown-viewed {
		.card-price {
			max-width: 400px;
		}
	}
}

@media screen and (max-width: 1023px) {
	.card-price {
		width: calc(100% - 485px);
	}

	.card-price__credit {
		flex-direction: column;
	}

	.card-price__top {
		flex-direction: column;
		padding: 8px;
	}

	.card-price__discount span {
		padding: 0 3px;
	}

	.card-price__discount {
		margin: 10px 0 0;
		padding: 4px 7px;
		white-space: nowrap;
	}

	.card-price__value {
		padding: 2px 0 0px;
	}

	.card-price__credit {
		padding: 13px 0 24px;
	}

	.card-price__credit-value {
		margin-bottom: 12px;
	}

	.card-price__cheap-link {
		line-height: 18px;
	}

	.box-card-tabs .nav-tab-list {
		justify-content: space-between;
	}

	.box-card-tabs .nav-tab-list__item {
		width: auto;
		flex: 1;
	}

	.box-card-tabs .nav-tab-list__link {
		padding: 19px 10px;
	}

	.feedback-form {
		padding: 50px 53px 35px;
	}

	.feedback-form-wrap {
		margin-top: 40px;
	}

	.feedback-form__bottom {
		flex-wrap: wrap;
	}

	.feedback-form__cancel-wrap {
		order: 1;
		width: 100%;
		margin-top: 4px;
		text-align: right;
	}

	.feedback-form__cancel {
		width: 180px;
		text-align: center;
	}

	.feedback-box__row .col_1 {
		width: 78px;
	}

	.feedback-box__row .col_2 {
		width: calc(100% - 78px);
	}

	.feedback-box__name {
		min-width: 78px;
	}

	.video-list-btn {
		text-align: left;
	}
}

@media screen and (max-width: 991px) {
	.markdown-viewed {
		flex-direction: column;

		.card-price {
			max-width: 100%;
			padding-left: 0;
			margin-top: 16px;

			&__credit {
				flex-direction: row;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.box-card__wrap {
		padding: 15px 15px 25px;
	}

	.card-info-top h1 {
		font-size: 20px;
		line-height: 27px;
	}

	.card-info-top__compare {
		width: 100%;
		margin-top: 9px;
		margin-left: 0;
	}

	.card-info-top__bottom {
		flex-wrap: wrap;
	}

	.card-price {
		width: 100%;
		padding-left: 0;
		padding-top: 20px;
	}

	.card-slider-box {
		width: 100%;
	}

	.card-slider {
		width: 100%;
	}

	.card-price__credit {
		flex-direction: row;
	}

	.card-price__top {
		flex-direction: row;
	}

	.card-price__discount {
		margin: 0;
		padding: 4px 10px;
	}

	.card-price__discount span {
		padding: 0 3px 0 9px;
	}

	.card-price__value {
		padding: 14px 0 4px;
	}

	.card-price__credit-value {
		margin-bottom: 0;
	}

	.card-price__credit {
		padding: 15px 0 24px;
	}

	.card-info__row:first-child {
		border-top: 1px solid rgba(156, 185, 200, 0.2);
	}

	.box-card-tabs__wrap {
		margin: 0 -20px;
	}

	.box-card-tabs .tab-cont {
		padding: 30px 20px 25px;
	}

	.box-card-tabs .nav-tab-list {
		padding: 0 20px;
	}

	.box-card-tabs .nav-tab-list__link {
		padding: 21px 5px;
		font-weight: 500;
		font-size: 16px;
		line-height: normal;
		background-color: #fff;
	}

	.box-card-tabs .nav-tab-list__item::after {
		display: none;
	}

	.box-card-tabs .nav-tab-list__item.active .nav-tab-list__link {
		color: $color-3;
	}

	.card-tabs-desc h3 {
		font-size: 20px;
		line-height: 27px;
		padding-bottom: 0;
	}

	.card-tabs-desc p {
		color: rgba(51, 51, 51, .5);
	}

	.box-card-tabs .slick-arrow {
		font-size: 12px;
	}

	.box-card-tabs .slick-arrow.slick-prev {
		left: 20px;
	}

	.box-card-tabs .slick-arrow.slick-next {
		right: 20px;
	}

	.card-info-position__icon {
		width: 18px;
	}

	.card-info-position__name {
		width: calc(100% - 18px);
	}

	.card-info-position__name i {
		top: -3px;
	}

	.feedback-form {
		padding: 40px 25px;
	}

	.feedback-form__bottom {
		margin-top: 0;
	}

	.feedback-form__send {
		margin-top: 40px;
		width: 100%;
		text-align: center;
	}

	.feedback-form__cancel-wrap {
		margin-top: 18px;
		text-align: center;
	}

	.video-list {
		width: auto !important;
		margin: -5px;
	}

	.video-list__item {
		padding: 5px;
	}

	.video-list-wrap::after {
		display: none;
	}

	.video-list-pagination {
		display: none;
	}

	.video-list__item {
		width: 100%;
		max-width: 514px;
	}
}

@media screen and (max-width: 500px) {
	.card-slider-nav {
		display: none;
	}

	.card-info-top__feedback {
		width: 100%;
		margin-top: 17px;
	}

	.card-info-top {
		padding-bottom: 12px;
		margin-bottom: 15px;
	}

	.card-slider-wrap {
		width: 100%;
		padding-left: 0;
	}

	.card-slider__item {
		height: 250px;
	}

	.card-slider .slick-dots {
		bottom: 13px;
	}

	.card-info-position {
		flex-wrap: wrap;
	}

	.card-info-position__name {
		width: calc(100% - 18px);
		margin: 0;
		padding-left: 10px;
	}

	.card-info-list-bottom {
		padding-bottom: 8px;
	}

	.card-info-list-bottom__item::before {
		top: 12px;
	}

	.card-info-list-bottom__item {
		padding: 6px 0px 6px 20px;
		line-height: 19px;
	}

	.card-info__time {
		padding: 7px 0 10px;
	}

	.card-info-position__btn {
		width: 100%;
		margin-top: 15px;
		text-align: center;
		flex: 1 0 auto;
	}

	.instruction-list__download {
		align-items: flex-start;
	}

	.feedback-box__top {
		flex-wrap: wrap;
		padding: 20px 24px;
	}

	.feedback-box__row {
		flex-wrap: wrap;
		padding: 10px 24px;
	}

	.feedback-box__row .col {
		width: 100%;

		&_1 {
			margin-bottom: 12px;
		}
	}

	.feedback-box__item {
		padding-bottom: 13px;
	}

	.feedback-box__rating {
		order: 1;
		width: 100%;
		margin-top: 10px;
	}

	.video-list__info {
		padding: 12px 10px;
	}

	.block-hint {
		.attribute-hint {
			top: 15px;
			left: -100px;
		}
	}

	.box-card-tabs {
		#tab-gifts {
			.product-gifts {
				.item {
					flex-direction: column;
					width: 100%;
					margin-bottom: 20px;

					.title {
						max-width: unset;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 365px) {
	.markdown-viewed {
		.card-price {
			&__credit {
				i {
					display: none;
				}
			}
		}
	}
}

.country_flag {
	width: auto;
	display: inline-block;
	line-height: 31px !important;
	img {
		display: inline-block;
		float: right;
		padding-left: 10px;
		position: relative !important;
		bottom: 0 !important;
	}
}