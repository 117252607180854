//Main styles

.main-wrapper {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	min-width: $min-width;
	min-height: 100%;
	padding: 0 0 0 0;
	background-color: $base-bg;
}

.wrapper {
	width: 100%;
	min-width: $min-width;
	max-width: $max-width;
	margin: 0 auto;
	padding: 0 40px 0 40px;
}

p {
	padding: 10px 0;
	font-size: $font-size-p;
	line-height: $line-height-p;
}

h1 {
	padding: 10px 0;
	line-height: $font-size-h1;
	font-size: $line-height-h1;
	font-weight: normal;
}

h2 {
	padding: 10px 0;
	font-size: $font-size-h2;
	line-height: $line-height-h2;
	font-weight: normal;
}

h3 {
	padding: 10px 0;
	font-size: $font-size-h3;
	line-height: $line-height-h3;
	font-weight: 500;
}

h4 {
	padding: 10px 0;
	font-size: $font-size-h4;
	line-height: $line-height-h4;
	font-weight: 600;
}

//Text position
.text-left {
	text-align: left !important
}

.text-center {
	text-align: center !important
}

.text-right {
	text-align: right !important
}

.nowrap {
	white-space: nowrap !important;
}

/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/

.content {
	flex-grow: 1;
	width: 100%;
	min-width: $min-width;
	order: 2;
	text-align: left;
}

.mask {
	position: fixed;
	z-index: -1;
	top: -50%;
	left: 0;
	width: 120%;
	height: 300%;
	background: rgba(58, 100, 121, .8);
	opacity: 0;
	transition: $transition;

	&.active {
		z-index: 997;
		opacity: 1;
	}
}

.no-scroll {
	overflow: hidden;

	.web {
		padding-right: 17px;
	}
}

.btn, .modal .callback_button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	padding: 0px 22px;
	border: 2px solid $color-2;
	border-radius: 50px;
	color: $color-1;
	background-color: transparent;
	font-size: 14px;
	line-height: normal;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
	transition: $transition;

	&:hover {
		background-color: $color-2;
		color: #fff;
	}
}

.btn_inverse {
	background-color: $color-2;
	color: #fff;

	&:hover {
		color: $color-1;
		background-color: transparent;
	}
}

.btn_more {
	i {
		display: none;
		margin-left: 9px;
		color: #3A6479;
	}
}

input {
	display: block;
	width: 100%;
	height: 50px;
	padding: 0 19px;
	border: 2px solid #E8E8E8;
	border-radius: 50px;
	background: #FFFFFF;
	font-size: 14px;
	line-height: 16px;
	transition: $transition;

	&:focus {
		border-color: $color-2;
	}

	&::placeholder {
		color: rgba(51, 51, 51, .5);
	}
}

textarea {
	width: 100%;
	height: 90px;
	padding: 13px 21px;
	background: #FFFFFF;
	border: 1px solid #DAE4EA;
	border-radius: 4px;
	font-family: $custom-font-family;
	font-size: 14px;
	line-height: 20px;
	color: rgba(51, 51, 51, .78);
	transition: $transition;

	&:focus {
		border-color: $color-2;
	}

	&::placeholder {
		color: rgba(51, 51, 51, .5);
	}
}

section,
.section {
	margin: 20px 0;
}

.section-wrap {
	padding: 23px 46px 34px;
	background-color: #fff;

	& > h1,
	& > h2,
	& > h3,
	& > h4,
	& > h5 {
		padding-top: 0;
	}
}

.back-call {
	position: fixed;
	z-index: 100;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
	cursor: pointer;
	&__icon {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 56px;
		height: 56px;
		border-radius: 50%;
		background: #76CC61;
		color: #fff;
		box-shadow: 0px 14px 28px rgba(141, 203, 126, 0.38);
		font-size: 20px;

		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			border: 1px solid rgba(118, 204, 97, .4);
			transform: translate(-50%, -50%);
			transition: $transition;
			animation: circle 2s linear normal infinite;
		}
	}

	&__text {
		margin-top: 10px;
		color: rgba(0, 0, 0, .6);
		font-size: 10px;
		line-height: 14px;
		text-transform: uppercase;
		text-align: center;
		text-shadow: 1px 1px 0 #fff;
	}
}

.scroll-top {
	position: fixed;
	z-index: 100;
	bottom: 30px;
	right: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	padding-left: 2px;
	background-color: $color-3;
	color: #fff;
	opacity: 0;
	font-size: 16px;
	transform: rotate(-90deg);
	cursor: pointer;
	transition: $transition;

	&.active {
		opacity: 1;
	}

	&:hover {
		opacity: .85;
	}
}

@keyframes circle {
	0% {
	}

	100% {
		width: 170%;
		height: 170%;
		opacity: 0;
	}
}

.main-slider {
	&__item {
		overflow: hidden;
		height: 452px;
		display: flex !important;
		align-items: center;
	}

	&__info {
		position: relative;
		z-index: 2;
		width: 45%;
		padding: 0 100px;
	}

	&__img {
		position: absolute;
		display: block;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}

	&__btn {
		margin-top: 12px;
	}

	.btn {
		height: 50px;
		padding: 0 53px;
	}

	&__desc {
		p, li {
			font-size: 20px;
			font-weight: 300;
			line-height: 1.8;
		}
		h2 {
			font-size: 48px;
			font-weight: 700;
		}
		h3 {
			font-size: 35px;
			font-weight: 700;
		}
		h4 {
			font-size: 30px;
			font-weight: 300;
			line-height: 1.3;
			b {
				font-weight: 700;
			}
		}
		ul {
			margin: 30px 0;
			li {
				&:before {
					content: "\2014\a0";
					text-indent: -5px;
				}
			}
		}
	}

	.slick-arrow {
		font-size: 33px;
	}

	.slick-arrow.slick-prev {
		left: 20px;
	}

	.slick-arrow.slick-next {
		right: 20px;
	}
}

/*------------------------------*/

.box-check-banner {
	overflow: hidden;

	.col_1 {
		position: relative;
		width: 50%;
		padding: 17px 0 17px 116px;
		background-color: $color-3;

		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			top: 50%;
			right: -40px;
			display: block;
			width: 300px;
			height: 200px;
			border-radius: 50%;
			background-color: $color-3;
			transform: translateY(-50%);
			transition: $transition;
		}

	}

	.col_2 {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 50%;
		padding: 17px 100px 17px 0;
	}

	&__wrap {
		display: flex;
		background-color: $color-4;
		background-repeat: no-repeat;
		background-position: 68.5% center;
		background-size: 100%;
	}

	h2 {
		position: relative;
		z-index: 2;
		color: #fff;
		font-size: 28px;
		line-height: 33px;
	}

	.btn {
		padding: 0px 57px;

		&:hover {
			color: #fff;
		}
	}
}

/*------------------------------*/

.category-banner {
	display: flex;
	align-items: stretch;
	margin: -10px;

	$this: &;

	.col {
		width: 50%;
		padding: 10px;
	}

	.col_1 {
		display: flex;
	}

	.col_1 #{$this}__item {
		flex: 1;
		border: 1px solid #000000;
	}

	.col_2 #{$this}__item:not(:last-child) {
		margin-bottom: 20px;
	}

	h3 {
		position: relative;
		z-index: 2;
		color: #FFFFFF;
	}

	&__item {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 72px 30px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		text-align: center;

		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			background: rgba(58, 100, 121, 0.7);
			transition: 1s;
		}

		&:hover::after {
			background: rgba(58, 100, 121, 0);
		}
	}
}

/*------------------------------*/

.services-banner {
	display: flex;
	margin: -10px;

	$this: &;

	.col {
		width: 50%;
		padding: 10px;

		&_1 {
			#{$this}__info {
				padding-left: 59px;
			}
		}

		&_2 {
			margin: 0 auto;

			#{$this}__wrap {
				justify-content: space-between;
			}

			#{$this}__info {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-left: 61px;
			}
		}
	}

	&__wrap {
		display: flex;
		align-items: center;
		height: 100%;
		padding: 10px 45px;
		background-color: $color-4;
	}

	&__info {
		width: calc(100% - 90px);
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 90px;
		height: 90px;
		border-radius: 50%;
		background-color: $color-3;
		font-size: 60px;
		color: #fff;
	}

	h2,a {
		padding: 0;
		color: #fff;
	}

	p {
		padding: 5px 0 0;
		color: rgba(255, 255, 255, .5);
		font-size: 16px;
		line-height: 19px;
	}

	.btn {
		color: #fff;
		font-weight: normal;
	}
}

/*------------------------------*/

.product-list {
	.slick-list {
		padding: 0 6px;
		margin: -20px -12px;
	}

	.slick-track {
		display: flex;
	}

	$this: &;

	&-wrap {
		margin-top: 5px;
	}

	&__item {
		height: inherit;
		width: 20%;
		padding: 20px 6px;
	}

	&__wrap {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 14px;
		border: 1px solid #E8E8E8;
		background: #FFFFFF;
		transition: $transition;

		&:hover {
			box-shadow: 0px 8px 18px rgba(94, 94, 94, 0.2);
		}
	}

	&__img-wrap {
		position: relative;
		display: block;
		margin: -14px;
		margin-bottom: 0;

		&:hover {
			#{$this}__look {
				opacity: 1;
			}
		}
	}

	&__img {
		position: relative;
		z-index: 1;
		display: block;
		width: auto;
		height: 190px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
	}

	&__info {
		position: absolute;
		z-index: 2;
		top: 9px;
		left: 9px;
		display: flex;
		flex-wrap: wrap;
		padding-right: 50px;
	}

	&__stock,
	&__novelty,
	&__day {
		display: flex;
		align-items: center;
		border-radius: 40px;
		padding: 2px 10px;
		margin: 0 8px 11px 0;
		background: #F7585A;
		color: #fff;
		font-size: 12px;
		line-height: normal;
		white-space: nowrap;

		i {
			font-size: 8px;
			margin-right: 5px;
		}
	}

	&__day {
		background-color: #A674E3;

		i {
			font-size: 10px;
		}
	}

	&__novelty {
		background-color: #6abd91;

		i {
			font-size: 10px;
		}
	}

	&__compare {
		position: absolute;
		z-index: 2;
		top: 9px;
		right: 9px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		color: $color-1;
		background: #E8E8E8;
		font-size: 17px;
		line-height: normal;
		cursor: pointer;
		transition: $transition;

		&:hover {
			color: #fff;
			background-color: $color-3;
		}
	}

	&__availability,
	&__order {
		position: absolute;
		z-index: 2;
		bottom: -8px;
		left: 50%;
		padding: 3px 14px;
		border-radius: 40px;
		color: #fff;
		background: #6abd91;
		font-size: 12px;
		line-height: 14px;
		transform: translateX(-50%);
		white-space: nowrap;
	}

	&__look {
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 44px;
		height: 44px;
		margin-right: 10px;
		border: 2px solid $color-3;
		border-radius: 50%;
		background-color: rgba(58, 100, 121, .9);
		color: #fff;
		opacity: 0;
		font-size: 17px;
		line-height: normal;
		transform: translate(-50%, -50%);

		&:hover {
			background-color: rgba(58, 100, 121, 1);
			color: #fff;
		}
	}

	&__order {
		background-color: #FFB300;
	}

	&__name {
		margin: 20px 0 9px;
		font-family: $custom-font-family;
		font-size: 16px;
		line-height: 20px;
		font-weight: 600;
	}

	&__rating {
		margin-top: auto;

		.jq-star {
			cursor: default;
		}
	}

	&__remove {
		display: inline-block;
		font-size: 13px;
		line-height: 13px;
		border-bottom: 1px solid;
		color: rgba(51, 51, 51, .4);
		cursor: pointer;
		transition: $transition;

		&:hover {
			color: $color-2;
			border-bottom-color: transparent;
		}
	}

	&__quantity {
		align-self: flex-start;
		display: flex;
		align-items: center;
		width: 88px;
	}

	&__quantity-reduce,
	&__quantity-increase {
		position: relative;
		display: inline-block;
		width: 26px;
		height: 26px;
		border: 1px solid rgba(51, 51, 51, .3);
		border-radius: 50%;
		padding: 0;
		background: #FFFFFF;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		cursor: pointer;
		transition: $transition;

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			height: 2px;
			width: 10px;
			background-color: $color-1;
			transform: translate(-50%, -50%);
		}

		&:hover {
			border-color: $color-2;
		}

		&:active {
			box-shadow: inset 0 0 5px 1px $color-2;
		}

	}

	&__quantity-increase {
		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			height: 10px;
			width: 2px;
			background-color: $color-1;
			transform: translate(-50%, -50%);
		}
	}

	&__quantity-value {
		width: 36px;
		height: 20px;
		border: none;
		padding: 0;
		font-size: 16px;
		line-height: normal;
		text-align: center;
	}

	&__bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 24px;
	}

	&__price-wrap {
		position: relative;
	}

	&__price-count {
		font-weight: 500;
		font-size: 13px;
		line-height: 15px;
		color: rgba(51, 51, 51, .3);
	}

	&__price-old {
		position: absolute;
		top: -20px;
		left: 0;
		color: rgba(51, 51, 51, .3);
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		text-decoration-line: line-through;
	}

	&__price {
		font-size: 22px;
		line-height: 26px;
		font-weight: 500;
		white-space: nowrap;

		i {
			font-size: 15px;
		}
	}

	&__buttons {
		display: flex;
	}

	&__buy {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 44px;
		height: 44px;
		margin-right: 10px;
		border: 2px solid $color-3;
		border-radius: 50%;
		color: $color-3;
		font-size: 24px;
		line-height: normal;
		background: none;
		padding: 0;
		&:hover {
			color: #fff;
			background-color: $color-3;
		}
		i {
			margin: 0 auto;
		}
	}

	&__basket {
		padding: 0;
		width: 44px;
		height: 44px;
		border-radius: 50%;
		border: 2px solid $color-2;
		background-color: $color-2;
		color: #fff;
		font-size: 22px;
		line-height: normal;

		&:hover {
			color: $color-2;
			background-color: #fff;
		}
	}

	.btn {
		margin: 2px 0;
	}
}

.product-list-more {
	display: none;
	margin-top: 22px;
	text-align: center;
}

/*------------------------------*/

.box-news {
	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: -9px;

		.btn {
			padding: 0 38px;
		}
	}

	.section-wrap {
		padding-bottom: 18px;
	}
}

.news-list {
	display: flex;
	flex-wrap: wrap;
	margin: -6px;

	$this: &;

	&-wrap {
		margin-top: 10px;
	}

	&__item {
		width: 25%;
		padding: 6px;
	}

	&__wrap {
		border: 1px solid #E8E8E8;
		color: $color-1;
		height: 100%;
		&:hover {
			box-shadow: 0 8px 18px rgba(92, 104, 112, 0.2);

			#{$this}__detail::after {
				background-color: $color-2;
			}
		}
	}

	&__img {
		height: 220px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__info {
		padding: 18px 16px 23px;
	}

	&__date {
		color: rgba(58, 100, 121, .6);
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;
	}

	&__detail {
		position: relative;
		display: inline-block;
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: calc(100% + 10px);
			display: block;
			width: 40px;
			height: 1px;
			background-color: #648798;
			transform: translateY(-50%);
			transition: $transition;
		}
	}

	&__more {
		display: none;
		margin-top: 20px;
		text-align: center;
	}

	h4 {
		color: $color-1;
		font-family: $custom-font-family;
	}

	p {
		padding: 0 0 17px;
		color: rgba(51, 51, 51, .8);
		font-family: $custom-font-family;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
	}
}

.pagination-list {
	display: flex;
	align-items: center;
	margin: 0 -5px;

	&-wrap {
		display: flex;
		justify-content: flex-end;
		margin-top: 21px;
	}

	&__item {
		padding: 0 5px;

		&.active {
			.pagination-list__link {
				border: 1px solid $color-2;
				box-shadow: inset 0 0 0 1px $color-2;
			}
		}
	}

	&__link {
		width: 34px;
		height: 34px;
		border: 1px solid #E8E8E8;
		border-radius: 50%;
		background: #FFFFFF;
		font-size: 15px;
		line-height: 32px;
		text-align: center;

		&:hover {
			border: 1px solid $color-2;
			box-shadow: inset 0 0 1px 1px $color-2;
			color: $color-1;
		}
	}

	&__link_arrow {
		font-size: 9px;
	}
}

/*------------------------------*/

.box-subscribe {
	background-color: #fff;

	&__wrapper {
		background-color: #fff;
	}

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 24px 89px 24px 56px;
	}

	&__icon {
		width: 57px;
		color: $color-2;
		font-size: 57px;
	}

	&__info {
		padding-right: 17px;
	}

	h2 {
		padding: 0;
	}

	p {
		padding: 5px 0 0;
		color: rgba(51, 51, 51, .5);
		font-size: 16px;
		line-height: 19px;
	}

	.btn {
		padding: 0 46px;
	}
}

.subscribe-form {
	&__wrap {
		display: flex;
	}

	&__input-wrap {
		width: 330px;
		max-width: 100%;
		margin-right: 20px;
	}

	.btn {
		height: 50px;
	}
}

/*------------------------------*/

.box-banner-price {
	&__wrap {
		position: relative;
		padding: 12px 20px 28px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		color: #fff;
		text-align: center;

		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			background: rgba(58, 100, 121, 0.7);
			transition: $transition;
		}

		& > * {
			position: relative;
			z-index: 2;
		}
	}

	h2 {
		padding-bottom: 0;
	}

	p {
		padding: 3px 0 22px;
		font-size: 15px;
		line-height: 18px;
	}

	.btn {
		height: 50px;
		padding: 0 55px;
		color: #fff;
	}
}

/*------------------------------*/

.box-about {
	&__wrap {
		padding: 17px 117px 20px;
		background-color: #fff;
	}

	h4 {
		padding: 0;
		font-family: $custom-font-family;
	}

	h4 + div {
		position: relative;
		overflow: hidden;
		max-height: 80px;
		color: rgba(51, 51, 51, .6);
		font-size: 14px;
		line-height: 20px;
		font-family: $custom-font-family;

		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 30px;
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 98.44%);
			transition: $transition;
		}

		&.active {
			max-height: none;

			&::after {
				display: none;
			}
		}
	}

	&__more {
		display: inline-block;
		border-bottom: 1px dashed rgba(100, 135, 152, .4);
		margin-top: 3px;
		font-size: 14px;
		line-height: 19px;
		font-weight: 500;
		cursor: pointer;
		transition: $transition;

		&:hover {
			color: $color-2;
			border-color: transparent;
		}

		&.active {
			span:first-child {
				display: none;
			}
		}

		&:not(.active) {
			span:last-child {
				display: none;
			}
		}
	}
}

/*-------------------------------------------------------------------------------*/

.info_page {
	padding: 40px 49px 40px;
	background-color: #fff;
	display: inline-block;
	width: 100%;
	ul {
		list-style-type: disc;
		padding-left: 20px;
		margin-top: 10px;
		ul {
			list-style-type: circle;
		}
		li {
			padding: 10px 0;
			font-size: 14px;
			line-height: 1.4;
		}
	}
}

.news_item {
	background: white;
	padding: 10px;
	p {
		padding: 0 0 15px 0px;
	}
	h3 {
		color: #333333;
		font-family: "Gilroy", sans-serif;
		padding: 10px 0;
		font-size: 18px;
		line-height: 20px;
		font-weight: 600;
	}
}

#news_list.news-list {
	margin: 20px 0;
}

.news_column {
	display: none;
}

.card-info-top__availability.product_order {
	background: #FFB300;
}

@media screen and (max-width: 1460px) {
	.box-subscribe__wrap {
		padding: 24px 0;
	}

	.box-subscribe__info {
		width: calc(100% - 57px);
		padding-right: 0;
	}
	.services-banner .col_2 .services-banner__info {
		padding-left: 15px; }
}

@media screen and (max-width: 1310px) {
	.services-banner .col_2 .services-banner__info h2 {font-size: 28px}
}

@media screen and (max-width: 1279px) {
	.wrapper {
		padding: 0 20px;
	}

	.product-list .btn {
		padding: 0 12px;
	}

	.section-wrap {
		padding: 20px;
	}

	h2 {
		font-size: 22px;
		line-height: 26px;
	}

	.main-slider__info {
		padding: 0 50px 0 70px;
	}

	.main-slider__desc {
		h2 {
			font-size: 32px;
			line-height: 40px;
		}
		ul {
			margin: 0 0 20px 0;
		}
	}

	.box-check-banner .col_1 {
		background-color: transparent;
	}

	.news-list__item {
		width: 33.33%;
	}

	.news-list__item:nth-child(n + 4) {
		display: none;
	}

	.box-check-banner__wrap {
		display: flex;
		justify-content: space-between;
		padding: 0 40px;
		background-color: $color-3;
	}

	.box-check-banner .col {
		width: auto;
		padding: 20px 0;

		&::after {
			display: none;
		}
	}

	.services-banner .col_1 .services-banner__info,
	.services-banner .col_2 .services-banner__info {
		padding-left: 20px;
	}

	.services-banner .col_2 .services-banner__info {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.services-banner__btn {
		margin-top: 12px;
	}

	.box-subscribe__info {
		padding-left: 32px;
	}

	.subscribe-form {
		margin-top: 21px;
		padding-left: 88px;
	}

	.subscribe-form__input-wrap {
		width: 301px;
		max-width: 100%;
		margin-right: 28px;
	}

	.box-subscribe__wrap {
		flex-wrap: wrap;
		justify-content: flex-start;
		max-width: 644px;
		padding: 23px 19px 35px;
		margin: 0 auto;
	}

	.box-subscribe p {
		padding: 10px 0 0;
	}
}

@media screen and (max-width: 1023px) {
	.main-slider {
		&__item {
			height: 330px;
		}
		h2 {
			font-size: 25px;
			font-weight: 700;
		}
		p, li {
			font-size: 17px;
			line-height: 1.4;
			padding: 0;
		}
		h3 {
			font-size: 25px;
		}
		h4 {
			font-size: 20px;
			padding: 0 0 15px 0;
		}
	}

	.main-slider__btn {
		margin-top: 5px;
	}

	.main-slider .btn {
		height: 32px;
		padding: 5px 20px;
		font-weight: 500;
		font-size: 12px;
		line-height: 12px;
	}

	.product-list__look {
		opacity: 1;
	}

	.news-list__item {
		width: 50%;
	}

	.news-list__item:nth-child(n + 3) {
		display: none;
	}

	.back-call,
	.scroll-top {
		display: none;
	}

	.section-wrap {
		margin: 0 -20px;
	}

	.box-check-banner .col {
		padding: 5px 0;
	}

	.box-check-banner__wrap {
		padding: 0 20px 0 40px;
	}

	.product-list-wrap {
		margin-top: 12px;
	}

	.product-list {
		display: flex;
		flex-wrap: wrap;
		margin: -5px;

		$this: &;

		&.more {
			#{$this}__item {
				display: block;
			}
		}

		&__item {
			width: 33.3%;
			padding: 5px;

			&:nth-child(n + 4) {
				display: none;
			}
		}
	}

	.product-list-more {
		display: block;

		&.active {
			display: none;
		}
	}

	.category-banner {
		flex-direction: column;
	}

	.category-banner .col {
		width: 100%;
	}

	.services-banner__wrap {
		padding: 24px 11px;
	}

	.services-banner__icon {
		width: 83px;
		height: 83px;
		font-size: 54px;
	}

	.services-banner__info {
		width: calc(100% - 83px);
	}

	.services-banner .col_2 .services-banner__info {
		padding-left: 15px;
	}

	.box-news .btn {
		padding: 0 37px;
	}

	.box-news .section-wrap {
		padding-top: 27px;
		padding-bottom: 33px;
	}

	.news-list__info {
		padding: 15px 16px 26px;
	}

	.box-banner-price h2 {
		font-size: 32px;
		line-height: 39px;
	}

	.box-about__wrap {
		padding: 14px 42px 20px;
	}

	.box-about p {
		max-height: 90px;
		overflow: hidden;
		&.active {
			max-height: unset;
		}
	}

	.box-about__more {
		margin-top: 14px;
	}

	.box-subscribe {
		background-color: transparent;
	}

	.mfilter-free-container {
		padding: 0;
		border: none;
		position: absolute;
		z-index: 99;
		top: 20px;
		margin: 0 20px;
		box-shadow: 0 0 0 rgba(0, 0, 0, 0);
		transition: .2s ease;
		&.opened {
			box-shadow: 3px 3px 7px rgba(0, 0, 0, .5);
		}
		.margin_minus {
			margin: 0;
		}
		#mfilter-free-container-0 {
			padding: 5px 10px !important;
			overflow: auto !important;
			max-height: 2000px !important;
		}
		.mfilter-box {
			margin-bottom: 5px;
		}
		.mfilter-free-button {
			background: #587E8F;
			margin: 0;
			padding: 4px 20px 5px 17px;
			border: none;
			width: 100%;
			box-sizing: border-box;
			display: inline-block;
			position: relative;
			height: auto;
		}
	}
}

@media screen and (max-width: 767px) {
	.services-banner {
		flex-wrap: wrap;
	}
	.product_advantage {
		display: none !important;
	}
	.services-banner .col {
		width: 100%;
	}

	.main-slider {
		margin: -20px -20px 0;
		&__item {
			height: calc(100px + 25vw);
		}
		&__info {
			padding: 0 10px 0 30px;
		}
		&__desc {
			h2 {
				font-size: calc(10px + 2vw);
				line-height: 1;
			}
			ul {
				display: none;
			}
			p {
				font-size: calc(3px + 2vw);
			}
			h3 {
				font-size: calc(6px + 2vw);
				line-height: 1;
				padding: 5px 0;
			}
			h4 {
				font-size: calc(4px + 2vw);
			}
		}
		.btn {
			height: 25px;
			padding: 0 15px;
			font-size: 10px;
		}
		.slick-arrow {
			font-size: 25px;
			&.slick-prev {
				left: 5px;
			}
			&.slick-next {
				right: 5px;
			}
		}
	}

	.box-check-banner h2 {
		margin-bottom: 2px;
		font-size: 22px;
		line-height: 26px;
		text-align: center;
	}

	.box-check-banner__wrap {
		position: relative;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 5px 20px 18px;
		&:after {
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			background: rgba(58, 100, 121, 0.6);
			transition: $transition;
		}
	}

	.box-check-banner .col {
		position: relative;
		z-index: 2;
		padding: 0;
	}

	.product-list__item {
		width: 50%;

		&:nth-child(n + 3) {
			display: none;
		}
	}

	.subscribe-form {
		width: 100%;
		padding-left: 0;
		&__wrap {
			flex-wrap: wrap;
			justify-content: center;
		}
		&__input-wrap {
			margin-bottom: 15px;
		}
	}

	.subscribe-form__input-wrap {
		width: 100%;
	}

	.box-banner-price h2 {
		font-size: 26px;
		line-height: 30px;
	}
	.product-list__look {
		display: none;
	}
	.header-compare__icon {
		margin-top: 5px;
	}
	.header-compare__count {
		top: -8px;
		right: -18px;
	}
}

@media screen and (max-width: 500px) {
	.btn_more {
		border: none;
		background-color: transparent;
		font-size: 14px;
		line-height: 16px;
		font-weight: 600;

		&.active i {
			transform: rotate(180deg);
		}

		i {
			display: inline-block;
			text-shadow: 0 0;
			transition: $transition;
		}

		&:hover {
			color: $color-1;
			background-color: transparent;
		}
	}

	.product-list-more {
		margin-top: 10px;
		margin-bottom: -5px;
	}

	.section-wrap {
		padding: 12px 20px 14px;
	}

	.product-list__item {
		width: 100%;
	}

	.product-list-wrap {
		margin-top: 3px;
	}

	.services-banner {
		margin: -5px;
	}

	.services-banner .col {
		padding: 5px;
	}

	.services-banner__wrap {
		padding: 28px 11px;
	}

	.services-banner .col_1 .services-banner__info,
	.services-banner .col_2 .services-banner__info {
		padding-left: 14px;
	}

	.services-banner .col_2 .services-banner__wrap {
		padding: 22px 11px;
	}

	.news-list.more {
		.news-list__item:nth-child(n) {
			display: block;
		}
	}

	.news-list__more {
		margin-top: 10px;

		&.active {
			display: none;
		}
	}

	.box-news .section-wrap {
		padding-top: 20px;
		padding-bottom: 14px;
	}

	.news-list__item {
		width: 100%;
	}

	.news-list__item:nth-child(n + 2) {
		display: none;
	}

	.box-news .btn {
		padding: 0 24px;
	}

	.pagination-list-wrap {
		display: none;
	}

	.news-list__more {
		display: block;
	}

	.box-subscribe__wrap {
		align-items: flex-start;
		padding: 20px 19px 26px;
	}

	.box-subscribe__info {
		padding-left: 37px;
	}

	.box-subscribe p {
		margin-left: -94px;
		padding-top: 14px;
	}

	.subscribe-form {
		margin-top: 15px;
	}

	.subscribe-form__input {
		text-align: center;
	}

	.subscribe-form__wrap {
		flex-direction: column;
	}

	.subscribe-form .btn {
		margin-top: 14px;
		width: 100%;
		display: block;
	}

	.box-banner-price__wrap {
		padding: 10px 20px 22px;
	}

	.box-banner-price p {
		padding: 9px 0 15px;
	}

	.box-banner-price h2 {
		font-size: 22px;
		line-height: 24px;
	}

	.box-about__wrap {
		padding: 19px 22px 24px;
	}

	.box-about p {
		max-height: 144px;
	}

	.information-news-item h1 {
		font-size: 22px;
		line-height: 1.3;
	}
}

@media screen and (max-width: 359px) {
	.mfilter-free-container {
		width: calc(100vw - 40px);
	}
	.service-page__btn .btn {
		padding: 0 20px;
		font-size: 12px;
	}
	.services-banner {
		&__btn {
			width: 100%;
		}
		.btn {
			padding: 0 10px;
			font-size: 12px;
		}
	}
}